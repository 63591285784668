@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,500;0,700;0,800;1,600&display=swap");

.swiper-pagination-bullet-active {
  background-color: #ff5c17;
}
html {
  scroll-behavior: smooth;
}
.my-shadow {
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.6);
}
.my-shadow2 {
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.6);
}

.active {
  color: #ff5c17;
}

.my-shadow-red {
  box-shadow: 2px 4px 6px #ff5d1789;
  background-color: #ff5c17;
}

.my-shadow:hover {
  box-shadow: 2px 4px 6px #ff5d1789;
  background-color: #ff5c17;
}

.my-shadow-red:hover {
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.6);
  background-color: white;
}


.swiper-button-next::after {
  color: #ff5c17;
  object-fit: cover;
  font-size: 20px;
  font-weight: 700;
}
.swiper-button-prev::after {
  color: #ff5c17;
  object-fit: cover;
  font-size: 20px;
  font-weight: 700;
}



