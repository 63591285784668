.custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #343434;
    background-color: transparent;
    color: #343434;
    width: 100%;
  }
  
  .custom-file-upload input[type="file"] {
    display: none;
  }
  
  .custom-file-upload span {
    font-size: 16px;
    font-weight: bold;
  }
  
  /* ckeditor */
  .cke_contents {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    margin: 30px 0 !important;
  }
  
  .cke_wysiwyg_div {
    border: 1px solid lightgray !important;
    border-radius: 5px !important;
    width: 630px !important;
    height: 891px !important;
    padding: 30px !important;
  }
  
  .cke_toolbox {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
  }
  
  .cke_toolgroup {
    display: flex !important;
    align-items: center !important;
  }
  
  