.my-button2 {
  padding: 15px 25px;
  border: unset;
  color: #fff;
  z-index: 1;
  background: #fff;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  color: #ff5c17;
  border: 1px solid #ff5c17;
  transition: all 500ms;
  overflow: hidden;
}

.my-button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;

  background-color: #ff5c17;
  z-index: -1;

  transition: all 500ms;
}

.my-button2:hover {
  color: #fff;
}

.my-button2:hover::before {
  width: 100%;
}
