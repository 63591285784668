@keyframes rotateIn {
    0% {
        transform: scale(1.2);
    }
    50% {
        transform: rotate(1.4);
    }
    100% {
        transform: scale(1);
    }
}

.rotateIn {
    animation: rotateIn 1s ease-in-out infinite;
    transform: rotate(90deg);
}